<!-- 
@Author ——ZGM
@Date ——2022年
-->
<template>
  <div class="smartWater">
    <!-- banner -->
    <div class="banner"></div>

    <div class="LinkSulution">
      <div class="LinkContariner">
        <div class="left" :class="{ moveSuLeft: isMove }">
          <p>领氪智慧水务解决方案</p>
          <p>Smart Water</p>
          <p>
            <span></span>
          </p>
          <p class="commonP">
            领氪智慧水务以物联网、大数据、云计算为基础，通过建立城市水务物联网
          </p>
          <p class="commonP">
            监测体系，实现从原水、制水、供水、排水、水环境的整个水循环过程的实
          </p>
          <p class="commonP">
            时感知、监测、预警与分析，做到变被动为主动。并通过整合从原水、供水
          </p>
          <p class="commonP">
            再到排水的城市水务资源，打通城市水务服务连接通道，建立城市智慧水务
          </p>
          <p class="commonP">
            一体化联动机制，提升城市水资源的集约利用和集中管控能力。
          </p>
        </div>
        <div class="right" :class="{ moveSuRight: isMove }">
          <img src="../../assets/images/smartWater/water.jpg" alt="" />
        </div>
      </div>

      <!-- <div class="colover_1"></div>
      <div class="colover_2"></div> -->
    </div>

    <!-- BIM_GIs -->
    <div class="BIM_GIS">
      <div class="title">
        <p :class="{ BIM: isBIM }">基于“物联网+BIM+GIS”的数字水务底座</p>
      </div>

      <div class="BIM_GIS_Container">
        <img
          :class="{ GIS: isBIM }"
          src="../../assets/images/smartWater/water3.png"
          alt=""
        />
      </div>
    </div>

    <!-- iot_AI -->
    <div class="iot_AI">
      <div class="iotContainer">
        <div class="iotTitle">
          <p :class="{ AI_title: isAI }">基于“物联网+大数据AI”的数字孪生水厂</p>
        </div>

        <!-- 描述 -->
        <div class="desContainer">
          <div class="describe" :class="{ des_title: isAI }">
            <p>
              数字孪生水厂通过利用物联网、建筑信息模型（BIM）、大数据AI等技术，实现真实水厂到数字水厂的1:1投影和一体化，实现全数字化设计、工艺与生产信息链路贯通；
            </p>
            <p>
              并通过数字化的水厂工艺体系和大数据AI算法，实现水厂生产全过程的虚拟仿真验证，虚实结合。
            </p>
          </div>
        </div>
        <!-- 图片 -->
        <div class="photo">
          <img
            :class="{ photo_title: isAI }"
            src="../../assets/images/smartWater/water5.jpg"
            alt=""
          />
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="colover_5"></div>
      <div class="colover_6"></div> -->
    </div>

    <div class="GIS_layout">
      <div class="iotContainer">
        <div class="iotTitle">
          <p :class="{ GIS_title: isGIS }">基于GIS一张图的智慧管网应用</p>
        </div>

        <!-- 描述 -->
        <div class="desContainer">
          <div class="describe" :class="{ des_title_GIS: isGIS }">
            <p>
              智慧管网以GIS地理信息技术为基础，结合iLink©平台的物联网能力和LinkView的数据可视化能力，建立了从管网数字化管理、管网在线监测、管网巡检维修到空间大数
            </p>
            <p>
              据分析的GIS一张图应用。并能为水司提供从管网勘测、数据整理到数据导入的一站式服务，避免不同厂家导致的数据标准不统一、数据格式不一致的问题。
            </p>
          </div>
        </div>
        <!-- 图片 -->
        <div class="photo">
          <img
            :class="{ photo_title_GIS: isGIS }"
            src="../../assets/images/smartWater/water6.png"
            alt=""
          />
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="colover_5"></div>
      <div class="colover_6"></div> -->
    </div>

    <!-- 超精度计算 -->
    <div class="calculation">
      <div class="iotContainer">
        <div class="iotTitle">
          <p :class="{ CA_title: isCA }">
            基于“超高精度计量+智能调压”的漏控解决方案
          </p>
        </div>

        <!-- 图片 -->
        <div class="photo_ca">
          <img
            :class="{ photo_title_CA: isCA }"
            src="../../assets/images/smartWater/water7.jpg"
            alt=""
          />
        </div>

        <!-- 描述 -->
        <div class="desContainer">
          <div class="describe" :class="{ des_title_CA: isCA }">
            <p>
              领氪智能漏控管理基于城市数字三维底座，结合“智慧压力控制+超高精度计量+漏水噪声监测”，实现高精度（精确到秒级）的实时流量数据分析、智能减压控漏以及
            </p>
            <p>噪声监测，从而达到更加精细化和智能化的漏控分析与管理。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMove: false,
      isBIM: false,
      isAI: false,
      isGIS: false,
      isCA: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 元素滚动监听
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量

      if (scrollTop < 1055 && !this.isMove) {
        this.isMove = true;
      }

      if (
        (scrollTop > 1184 && scrollTop < 2150 && !this.isAI) ||
        (scrollTop < 2590 && scrollTop > 2580 && !this.isAI)
      ) {
        this.isAI = true;
      }

      if (
        (scrollTop > 440 && scrollTop < 450 && !this.isBIM) ||
        (scrollTop < 1956 && scrollTop > 450 && !this.isBIM)
      ) {
        this.isBIM = true;
      }

      if (
        (scrollTop > 1793 && scrollTop < 1800 && !this.isGIS) ||
        (scrollTop < 3355 && scrollTop > 1800 && !this.isGIS)
      ) {
        this.isGIS = true;
      }


      if (
        (scrollTop > 3028 && scrollTop <= 4128 && !this.isCA)
       
      ) {
        this.isCA = true;
      }
    },
  },
};
</script>

<style lang='less' scoped>
.smartWater {
  .banner {
    height: 500px;
    background: url("../../assets/images/smartWater/banner.jpg") no-repeat;
    background-size: 100% 500px;
  }

  .LinkSulution {
    height: 644px;
    padding-top: 106px;
    position: relative;
    overflow: hidden;
    .LinkContariner {
      width: 1200px;
      height: 538px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      .left {
        margin-top: 70px;
        position: absolute;
        left: -470px;
        p:nth-child(1) {
          color: #1f87e8;
          font-family: "Microsoft YaHei";
          font-size: 32px;
          font-weight: 400;
          height: 42px;
          line-height: 42px;
          letter-spacing: 1px;
        }

        p:nth-child(2) {
          color: #1f87e8;
          font-family: "Microsoft YaHei";
          font-size: 46px;
          font-weight: 600;
          padding-left: 5px;
          letter-spacing: 1px;
          height: 60px;
          line-height: 60px;
        }
        p:nth-child(3) {
          width: 90px;
          height: 6px;
          background: #4b4b4b;
          margin: 36px 0 30px 0;

          span {
            display: block;
            width: 3px;
            height: 6px;
            background: #fff;
            margin: 0 auto;
          }
        }
        .commonP {
          color: #898989;
          font-family: "Microsoft YaHei";
          font-size: 14px;
          width: 470px;
          height: 26px;
          line-height: 26px;
        }
      }

      .right {
        width: 600px;
        height: 430px;
        position: absolute;
        right: -600px;
        img {
          width: 600px;
          height: 430px;
        }
      }
    }
    .colover_1 {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      height: 644px;
      width: 350px;
    }

    .colover_2 {
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      height: 644px;
      width: 350px;
    }
  }

  .BIM_GIS {
    height: 696px;
    background: #2b2b2b;
    padding-top: 56px;
    position: relative;
    overflow: hidden;
    .title {
      width: 100%;
      text-align: center;
      height: 30px;
      margin-bottom: 40px;
    }
    p {
      width: 100%;
      text-align: center;
      font-family: "Microsoft YaHei";
      color: #fff;
      font-size: 30px;
      position: absolute;
      left: -900px;
    }
    .BIM_GIS_Container {
      padding: 0 142px;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      height: 520px;
      width: 934px;
      //   border: 1px solid red;
      margin: 0 auto;
      img {
        width: 934px;
        height: 510px;
        position: absolute;
        right: -940px;
        margin: 0 auto;
      }
    }
  }

  .iot_AI {
    height: 528px;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
    .iotContainer {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .iotTitle {
        width: 100%;
        height: 38px;
        text-align: center;
        font-family: "Microsoft YaHei";
        color: #1f87e8;
        font-size: 30px;
        margin-bottom: 54px;
        position: relative;
        overflow: hidden;

        p {
          position: absolute;
          right: -562px;
        }
      }

      .desContainer {
        height: 52px;
        margin-bottom: 62px;
        position: relative;
        overflow: hidden;

        .describe {
          color: #898989;
          font-size: 14px;
          font-family: "Microsoft YaHei";
          position: absolute;
          right: -1042px;

          p {
            line-height: 26px;
            text-align: center;
          }
        }
      }

      .photo {
        width: 918px;
        height: 170px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        img {
          width: 918px;
          height: 170px;
          position: absolute;
          right: -918px;
        }
      }
    }

    .colover_5 {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      height: 528px;
      width: 350px;
    }

    .colover_6 {
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      height: 528px;
      width: 350px;
      background: #fff;
    }
  }

  .GIS_layout {
    height: 726px;
    background: #f6f6f6;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
    .iotContainer {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .iotTitle {
        width: 100%;
        height: 38px;
        text-align: center;
        font-family: "Microsoft YaHei";
        color: #1f87e8;
        font-size: 30px;
        margin-bottom: 54px;
        position: relative;
        overflow: hidden;

        p {
          position: absolute;
          right: -562px;
        }
      }

      .desContainer {
        height: 52px;
        margin-bottom: 62px;
        position: relative;
        overflow: hidden;

        .describe {
          color: #898989;
          font-size: 14px;
          font-family: "Microsoft YaHei";
          position: absolute;
          right: -1042px;

          p {
            line-height: 26px;
            text-align: center;
          }
        }
      }

      .photo {
        width: 646px;
        height: 386px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        img {
          width: 646px;
          height: 386px;
          position: absolute;
          right: -918px;
        }
      }
    }

    .colover_5 {
      position: absolute;
      left: 0;
      top: 0;
      background: #f6f6f6;
      height: 726px;
      width: 350px;
    }

    .colover_6 {
      position: absolute;
      right: 0;
      top: 0;
      background: #f6f6f6;
      height: 726px;
      width: 350px;
    }
  }

  .calculation {
    height: 846px;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
    .iotContainer {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .iotTitle {
        width: 100%;
        height: 38px;
        text-align: center;
        font-family: "Microsoft YaHei";
        color: #1f87e8;
        font-size: 30px;
        margin-bottom: 54px;
        position: relative;
        overflow: hidden;

        p {
          position: absolute;
          right: -654px;
        }
      }

      .desContainer {
        height: 52px;
        position: relative;
        overflow: hidden;

        .describe {
          color: #898989;
          font-size: 14px;
          font-family: "Microsoft YaHei";
          position: absolute;
          right: -1042px;

          p {
            line-height: 26px;
            text-align: center;
          }
        }
      }

      .photo_ca {
        width: 984px;
        height: 484px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        margin-top: 13px;
        margin-bottom: 44px;
        img {
          width: 984px;
          height: 484px;
          position: absolute;
          right: -984px;
        }
      }
    }

    .colover_5 {
      position: absolute;
      left: 0;
      top: 0;
      background: #f6f6f6;
      height: 726px;
      width: 350px;
    }

    .colover_6 {
      position: absolute;
      right: 0;
      top: 0;
      background: #f6f6f6;
      height: 726px;
      width: 350px;
    }
  }
}
</style>

<style lang="less" scoped>
.moveSuLeft {
  animation: suLeft_1 1s ease forwards;
}

.moveSuRight {
  animation: suRight_1 1s ease forwards;
}

.BIM {
  animation: BIM_1 1s ease forwards;
}

.GIS {
  animation: GIS_1 1s ease forwards;
}
.AI_title {
  animation: AI_1 1s ease forwards;
}

.des_title {
  animation: AI_2 1s ease forwards;
}
.photo_title {
  animation: AI_3 1s ease forwards;
}

.GIS_title {
  animation: GIS_1 1s ease forwards;
}

.des_title_GIS {
  animation: GIS_2 1s ease forwards;
}
.photo_title_GIS {
  animation: GIS_3 1s ease forwards;
}

.CA_title {
  animation: CA_1 1s ease forwards;
}

.des_title_CA {
  animation: CA_2 1s ease forwards;
}
.photo_title_CA {
  animation: CA_3 1s ease forwards;
}

@keyframes suLeft_1 {
  from {
    left: -470px;
  }
  to {
    left: 0;
  }
}

@keyframes suRight_1 {
  from {
    right: -600px;
  }
  to {
    right: 0;
  }
}
@keyframes BIM_1 {
  from {
    left: -900px;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes GIS_1 {
  from {
    right: -940px;
  }
  to {
    right: 0px;
    background: #000;
  }
}

@keyframes AI_1 {
  from {
    right: -562px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes AI_2 {
  from {
    left: -1042px;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes AI_3 {
  from {
    right: -918px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes GIS_1 {
  from {
    right: -562px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes GIS_2 {
  from {
    left: -1042px;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes GIS_3 {
  from {
    right: -918px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes CA_1 {
  from {
    right: -654px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes CA_2 {
  from {
    left: -1042px;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes CA_3 {
  from {
    right: -984px;
  }
  to {
    right: 0;
    width: 100%;
  }
}
</style>
